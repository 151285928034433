import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react'
import { IBasket } from '@/entities'

interface IBasketContext {
  basket: IBasket
  amount: number
  setBasket: Dispatch<SetStateAction<IBasket>>
}

export const BasketContext = createContext<IBasketContext>({} as IBasketContext);

interface IBasketContextProps {
  initialBasket?: IBasket
}

export const BasketContextProvider = ({ children, initialBasket }: IBasketContextProps & PropsWithChildren) => {
  const [
    basket,
    setBasket
  ] = useState(initialBasket || {list: [], promo: null})

  const amount = basket.list.map(x => x.price * x.quantity).reduce((accumulator, currentValue) => {
    return accumulator + currentValue
  }, 0)

  return (
    <BasketContext.Provider
      value={{
        basket,
        setBasket,
        amount
      }}
    >
      {children}
    </BasketContext.Provider>
  )
}