import Modal from '@/components/simple/Modal'
import { useTranslations } from 'next-intl'
import NextLink from 'next/link'
import Image from 'next/image'
import { useBasket } from '@/hooks'
import { QuantityCounter } from '@/components/smart'
import { numericFormatter } from 'react-number-format'
import { useRouter } from 'next/navigation'
import {
  removeProductFromBasket,
  updateProductInBasket
} from '@/actions/basket.actions'

type TProps = {
  close: () => void
  checkout?: () => void
}

const BasketModal = ({ checkout, close }: TProps) => {
  const t = useTranslations('dialogs.cart-modal')
  const {
    basket,
    setBasket,
    amount
  } = useBasket()

  const router = useRouter();

  const handleProductRemoveAction = async (alias: string) => {
    const basket = await removeProductFromBasket(alias)
    setBasket(basket)
  }

  const handleCheckoutAction = () => {
    router.push('/checkout')

    if (checkout) {
      checkout()
    }

    close()
  }

  const handleQuantityOnChange = async (alias: string, quantity: number) => {
    const basket = await updateProductInBasket(alias, quantity)
    setBasket(basket)
  }

  const footer = (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-12 cart-footer'>
          {basket.list.length !== 0 && (
            <button
              type='button'
              className='green-btn d-inline-flex mx-md-0 mx-2 me-md-3'
              onClick={handleCheckoutAction}
            >
              {t('footer.submit')}
            </button>
          )}
          <button
            type='button'
            className='white-btn d-inline-flex mx-md-0 mx-2'
            onClick={close}
          >
            {t('footer.continue')}
          </button>
        </div>
      </div>
    </div>
  )

  return (
    <Modal
      title={t('title')}
      footer={footer}
      close={close}
      className='max-690'
    >
      <div className='container-fluid product-cart'>

        {basket.list.length > 0 && (
          <>
            <div className='row product-cart-list'>
              {basket.list.map((product, index) => {
                return (
                  <div className='col-12 product-item' key={index}>
                    <div className='img-box'>
                      <NextLink
                        href={`/products/${product.alias}`}
                        className="w-100 h-100"
                      >
                        <Image
                          className="object-fit-contain"
                          src={product.cover}
                          alt={product.title}
                          width={500}
                          height={500}
                        />
                      </NextLink>
                    </div>
                    <div className='product-info'>
                      <div className='product-name'>
                        <NextLink
                          href={`/products/${product.alias}`}
                          onClick={() => {
                            router.push(`/products/${product.alias}`)
                            close()
                          }}
                        >
                          {product.title}
                        </NextLink>
                      </div>
                      <div className='art'>{t('item.sku')} {product.article}</div>
                      <button
                        type='button'
                        className='remove-product'
                        onClick={() => handleProductRemoveAction(product.alias)}
                      >
                        {t('item.delete')}
                      </button>
                    </div>
                    <div className='product-total'>
                      <div className='cost'>
                        {numericFormatter(product.price.toString(), {
                          thousandSeparator: ' ',
                          decimalScale: 0,
                          suffix: ' грн'
                        })}
                      </div>
                      <QuantityCounter
                        counter={product.quantity}
                        setCounter={(value: number) => handleQuantityOnChange(product.alias, value)}
                      />
                    </div>
                  </div>
                )
              })}
            </div>

            <div className='row total-sum'>
              <div className='col-6 col-lg-7 label'>{t('total-label')}</div>
              <div className='col-6 col-lg-5 sum'>
                {numericFormatter(amount.toString(), {
                  thousandSeparator: ' ',
                  decimalScale: 0,
                  suffix: ' грн'
                })}
              </div>
            </div>
          </>
        )}

        {basket.list.length === 0 && (
          <div className='row total-sum'>
            <div className='col'>
              <p className='text-center fs-4 mt-4'>{t('empty')}</p>
            </div>
          </div>
        )}

      </div>
    </Modal>
  )
}

export default BasketModal
