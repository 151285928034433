import { useContext } from 'react'

import { BasketContext } from '@/context/basket-context'

export const useBasket = () => {
  const context = useContext(BasketContext);
  if (!context) {
    throw new Error('useBasketContext must be used within a BasketContextProvider');
  }

  return context;
}